//Colours
$control-text-color-dark: #424b5a;
$control-text-color-blue: #005cab;
$perspio-control-text-color-blue: #005cab;
$perspio-control-color-light-blue: #0177c7;
$perspio-highlight-color: #dee2e6;

$detail-value-text-color: #334155;

$control-border-color: #c2d1d9;
$control-highlight-blue: #005cab;

$panel-background-color: #ffffff;
$panel-border-color: #d9e2e7;
$breadcrumb-text-color: #002142;

$status-color-on: #9ae5eb;
$status-color-off: #005cab;
$status-color-offline: #c2d1d9;

$asset-map-marker-offline-color: #c2d1d9;
$asset-map-marker-on-color: #9ae5eb;
$asset-map-marker-off-color: #005cab;

// Criticality Colours

$criticality-info: #99d6ff;
$criticality-verbose: #00883f;
$criticality-minor: #ffbc00;
$criticality-major: #a73535;
$criticality-critical: red;

$primary-hover-color: #e7eaed;
$grid-header-color: rgb(250, 249, 251);
$grid-border-color: rgb(219, 214, 225);

// Lighter workflow type colors
$workflow-type-continuous-color: var(--mainColor);
$workflow-type-scheduled-color: var(--mainColor);
$workflow-type-event-color: var(--mainColor);
$workflow-type-periodic-color: var(--mainColor);
