@import 'variables';

.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload {
  background-color: var(--mainColor);
}

.uppy-Dashboard-Item-action,
.uppy-Dashboard-browse,
.uppy-Dashboard-Item-action--edit {
  color: #2196f3;

  &:hover {
    color: #2196f3;
  }
}

.uppy-StatusBar-progress {
  background-color: var(--mainColor);
}

.uppy-StatusBar-spinner,
.uppy-c-icon {
  fill: var(--mainColor);
}

.uppy-StatusBar-actions {
  justify-content: end;
}

.uppy-StatusBar-actionCircleBtn {
  display: none;
}

.uppy-Dashboard-AddFiles-info {
  display: none !important;
}

.uppy-Dashboard-browse:hover,
.uppy-Dashboard-browse:focus {
  border-bottom: 0;
}

.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload {
  color: #2196f3;
  border: 1px solid;
  font-size: 14px;
  font-weight: bold;
  border-radius: $border-radius;
  background-color: rgba(33, 150, 243, 0.16) !important;
  &:hover {
    background-color: rgba(33, 150, 243, 0.16) !important;
  }
}

.uppy-size--md .uppy-DashboardContent-back,
.uppy-size--md .uppy-DashboardContent-save {
  background-color: transparent;
  color: #2196f3;
  border-color: transparent;
}

.uppy-size--md .uppy-DashboardContent-back {
  display: none;
}
