@import '../styles/variables';
$ng-select-highlight: transparent;
$ng-select-primary-text: #333 !default;
$ng-select-disabled-text: #f9f9f9 !default;
$ng-select-border: #ccc !default;
$ng-select-border-radius: 0px !default;
$ng-select-bg: #ffffff !default;
$ng-select-selected: transparent;
$ng-select-marked: transparent;
$ng-select-box-shadow:
  inset 0 1px 1px rgba(0, 0, 0, 0.075),
  0 0 0 3px rgba(0, 126, 255, 0.1) !default;
$ng-select-placeholder: lighten($ng-select-primary-text, 40) !default;
$ng-select-height: 36px !default;
$ng-select-value-padding-left: 10px !default;
$ng-select-value-font-size: 0.9em !default;
.ng-select {
  font-family: $primary-font;
  &.ng-select-opened {
    > .ng-select-container {
      background: $ng-select-bg;
      border-color: darken($ng-select-border, 10) $ng-select-border
        lighten($ng-select-border, 5);
      &:hover {
        box-shadow: none;
      }
      .ng-arrow {
        top: -2px;
        border-color: transparent transparent darken($ng-select-border, 20);
        border-width: 0 5px 5px;
        &:hover {
          border-color: transparent transparent darken($ng-select-border, 60);
        }
      }
    }
    &.ng-select-bottom {
      > .ng-select-container {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    &.ng-select-top {
      > .ng-select-container {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      }
    }
  }
  &.ng-select-focused {
    &:not(.ng-select-opened) > .ng-select-container {
      border-color: $ng-select-highlight;
      box-shadow: $ng-select-box-shadow;
    }
  }
  &.ng-select-disabled {
    > .ng-select-container {
      background-color: $ng-select-disabled-text;
    }
  }
  .ng-has-value .ng-placeholder {
    display: none;
  }
  .ng-select-container {
    color: $ng-select-primary-text;
    background-color: $ng-select-bg;
    border-radius: $ng-select-border-radius;
    border: 1px solid $ng-select-border;
    min-height: $ng-select-height;
    align-items: center;
    &:hover {
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    }
    .ng-value-container {
      align-items: center;
      padding-left: $ng-select-value-padding-left;
      .ng-placeholder {
        color: $ng-select-placeholder;
      }
    }
  }
  &.ng-select-single {
    .ng-select-container {
      height: $ng-select-height;
      .ng-value-container {
        .ng-input {
          top: 5px;
          left: 0;
          padding-left: $ng-select-value-padding-left;
          padding-right: 50px;
        }
      }
    }
  }
  &.ng-select-multiple {
    &.ng-select-disabled {
      > .ng-select-container .ng-value-container .ng-value {
        background-color: $ng-select-disabled-text;
        border: 1px solid lighten($ng-select-border, 10);
        .ng-value-label {
          padding: 0 5px;
        }
      }
    }
    .ng-select-container {
      .ng-value-container {
        padding-top: 5px;
        padding-left: 7px;

        .ng-value {
          font-size: $ng-select-value-font-size;
          margin-bottom: 5px;
          background-color: $ng-select-selected;
          border-radius: 0px;
          margin-right: 5px;

          &.ng-value-disabled {
            background-color: $ng-select-disabled-text;
            .ng-value-label {
              padding-left: 5px;
            }
          }
          .ng-value-label {
            display: inline-block;
            padding: 1px 5px;
          }
          .ng-value-icon {
            display: inline-block;
            padding: 1px 5px;
            &:hover {
              background-color: darken($ng-select-selected, 5);
            }
            &.left {
              border-right: 1px solid darken($ng-select-selected, 10);
            }
            &.right {
              border-left: 1px solid darken($ng-select-selected, 10);
            }
          }
        }
        .ng-input {
          padding: 0 0 3px 3px;
        }
        .ng-placeholder {
          top: 5px;
          padding-bottom: 5px;
          padding-left: 3px;
        }
      }
    }
  }
  .ng-clear-wrapper {
    color: darken($ng-select-border, 20);
    &:hover .ng-clear {
      color: #d0021b;
    }
  }
  .ng-spinner-zone {
    padding: 5px 5px 0 0;
  }
  .ng-arrow-wrapper {
    width: 25px;
    padding-right: 5px;

    &:hover {
      .ng-arrow {
        border-top-color: darken($ng-select-border, 40);
      }
    }
    .ng-arrow {
      border-color: darken($ng-select-border, 20) transparent transparent;
      border-style: solid;
      border-width: 5px 5px 2.5px;
    }
  }
}
.ng-dropdown-panel {
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: $ng-select-bg;
  border: 1px solid $ng-select-border;

  left: 0;
  &.ng-select-bottom {
    top: 100%;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-color: lighten($ng-select-border, 10);
    margin-top: 4px;
    .ng-dropdown-panel-items {
      .ng-option {
        &:last-child {
          border-bottom-right-radius: 0px;
          border-bottom-left-radius: 0px;
        }
      }
    }
  }
  &.ng-select-top {
    bottom: 100%;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    border-bottom-color: lighten($ng-select-border, 10);
    margin-bottom: -1px;
    .ng-dropdown-panel-items {
      .ng-option {
        &:first-child {
          border-top-right-radius: 0px;
          border-top-left-radius: 0px;
        }
      }
    }
  }
  .ng-dropdown-header {
    border-bottom: 1px solid $ng-select-border;
    padding: 5px 7px;
  }
  .ng-dropdown-footer {
    border-top: 1px solid $ng-select-border;
    padding: 10px 24px;
    color: $control-highlight-blue;
    font-size: 12px;
    i {
      font-size: 10px;
    }
  }
  .ng-dropdown-panel-items {
    .ng-optgroup {
      user-select: none;
      padding: 8px 10px;
      font-weight: 400;
      color: $control-highlight-blue;
      cursor: pointer;
      &.ng-option-disabled {
        cursor: default;
      }
      &.ng-option-marked {
      }
      &.ng-option-selected,
      &.ng-option-selected.ng-option-marked {
        font-weight: 00;
      }
    }
    .ng-option {
      background-color: $ng-select-bg;
      color: $control-highlight-blue;
      padding: 8px 40px;
      font-size: 12px;
      &.ng-option-selected,
      &.ng-option-selected.ng-option-marked {
        color: $ng-select-primary-text;
        background-color: $ng-select-selected;
        .ng-option-label {
          font-weight: 600;
          color: #002142;
        }
      }
      &.ng-option-marked {
        background-color: $ng-select-marked;
        color: $ng-select-primary-text;
      }
      &.ng-option-disabled {
        color: lighten($ng-select-primary-text, 60);
      }
      &.ng-option-child {
        padding-left: 22px;
      }
      .ng-tag-label {
        font-size: 80%;
        font-weight: 400;
        padding-right: 5px;
      }
    }
  }
}
