.filter-dialog-container {
  p-tree {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .p-tree {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 0;
    height: 100%;
  }

  .p-tree .p-tree-filter-container {
    width: 100%;
  }

  p-chips {
    display: inline-block;
    flex-grow: 1;
  }

  .list-filter-chips {
    flex: 1;

    display: block;
    flex-grow: 1;
    flex-flow: column;
    margin-bottom: 2em;

    p-chips {
      display: inline-block;
      flex-grow: 1;
      padding-bottom: 2em;
    }

    .p-chips-token {
      display: inline-flex;
      align-items: center;

      flex: 0 0 auto;
      background-color: white;
      border: 1px solid #424b5a26;
    }
    .p-chips-token-icon {
      color: #424b5a;
      cursor: pointer;
    }

    .p-chips-token-label {
      color: #424b5a;
      font-size: 12px;
    }

    .p-chips-multiple-container {
      -ms-flex-align: center;
      align-items: center;
      cursor: text;
      display: -ms-flexbox;
      display: flex;
      list-style-type: none;
      margin: 0;
      overflow: hidden;
      padding: 0;
    }

    .p-chips-input-token {
      display: hidden;
    }
  }
}
