* {
  box-sizing: border-box;
}

.p-component {
  font-family: $fontFamily;
  font-size: $fontSize;
  font-weight: $fontWeight;
}

.p-component-overlay {
  background-color: $maskBg;
  transition-duration: $transitionDuration;
}

.p-disabled,
.p-component:disabled {
  opacity: $disabledOpacity;
}

.p-error,
.p-invalid {
  color: $errorColor;
}

.p-text-secondary {
  color: $textSecondaryColor;
}

.pi {
  font-size: $primeIconFontSize;
}

.p-link {
  font-size: $fontSize;
  font-family: $fontFamily;
  border-radius: $borderRadius;

  &:focus {
    @include focused();
  }
}

input:hover,
input:active,
input:focus {
  border-color: #2196f3 !important;
}

input:disabled {
  border-color: #c2d1d9 !important;
}
