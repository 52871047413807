.p-accordion {
  p-accordion-panel {
    box-shadow: none;
    margin-bottom: 0;
    border-radius: 0;
    border-top: none;
    border-left: none;
    border-right: none;
    position: relative;
    background-color: none;
    font-weight: 400;

    .p-accordionheader {
      flex-direction: row-reverse;

      .p-iconwrapper {
        margin-right: 0.5rem;
        height: 14px;
        width: 14px;
      }
    }
  }
}

.p-accordion
  .p-accordionheader:not(.p-disabled)
  .p-accordion-header-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-accordion .p-accordionheader {
  border: none;
  border-bottom: 1px solid #dee2e6;
}

.p-accordion
  .p-accordion-header
  .p-accordion-header-link
  .p-accordion-toggle-icon {
  font-size: 8px;
}

.p-accordion
  .p-accordion-header
  .p-accordion-header-link
  .p-accordion-toggle-icon.fa-chevron-down {
  font-size: 14px;
}
