@import 'palette/colors';

// primary font
$primary-font: 'Rubik';
$input-control-font: $primary-font;
$input-control-color: #555;
// font-weight
$thin: 100;
$light: 300;
$regular: 400;
$bold: 700;
$black: 900;
// Font size
$font-xs: 12px;
$font-sm: 14px;
$primary-fs: 16px;
$font-md: 18px;
$font-lg: 20px;

$delete-btn-height: 39px;
$error-color: #c41f3d;
$link-color-opt1: rgba(0, 92, 171, 0.6705882353);
$standard-background-color: var(--p-perspio-lightened-color);
$grey-background-color: #fafbfc;

// view toggle button;
$toggle-button-height: 40px;
$toggle-button-width: 46px;

$border-radius: 8px;
$border-radius-md: 12px;
$box-shadow-thin:
  0px 2px 3px -1px rgba(0, 0, 0, 0.1),
  0px 1px 0px 0px rgba(25, 28, 33, 0.02),
  0px 0px 0px 1px rgba(25, 28, 33, 0.08);

$box-shadow:
  rgba(60, 64, 67, 0.15) 0px 1px 2px 0px,
  rgba(60, 64, 67, 0.05) 0px 1px 3px 1px;

hover-shadow {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
    0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: $border-radius;
  border-color: rgb(243 244 246 / var(--tw-border-opacity));
}

.object-action-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  height: 75px;

  app-primary-button {
    width: 60%;
    &::ng-deep {
      .p-button {
        width: 100%;
      }
    }
  }
}

%form_error {
  font-size: 12px;
  color: $error-color;
}

// temp new buttons
//TODO: overide base styles
::ng-deep {
  .primary-button {
    font-weight: 600;
    color: #2196f3;
    border: 1px solid;
    background-color: rgba(33, 150, 243, 0.16) !important;
    &:hover {
      background-color: rgba(33, 150, 243, 0.16) !important;
    }
  }
  .p-button-outlined {
    box-shadow: none !important;
  }
}
