@import '../../../../styles/variables';

//these styles live here because they wont be visible to
//the template compiler once the svg is moved back into
//a file and imported with the inline svg directive

//when implementing new sgv icons- should use these classes for status colours
//

.marker-container {
  &.circle {
    box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.42);
    width: 20px;
    height: 20px;
    display: inline-block;
    position: relative;
    overflow: hidden;
    > svg {
      position: absolute;
      top: 1px;
      left: 1px;
    }
  }

  &.offline {
    .map-fill {
      fill: $asset-map-marker-offline-color;
    }
  }
  &.on {
    .map-fill {
      fill: $asset-map-marker-on-color;
    }
  }
  &.off {
    .map-fill {
      fill: $asset-map-marker-off-color;
    }
  }
}
