@import '../../../variables';

p-inputnumber.ng-dirty.ng-invalid > .p-inputnumber > .p-inputtext {
  @include invalid-input();
}

.p-inputnumber-button-up {
  border-radius: 0 $border-radius 0 0 !important;
}

.p-inputnumber-button-down {
  border-radius: 0 0 $border-radius 0 !important;
}

.p-inputnumber-input {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
