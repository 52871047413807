$default-font: 'Rubik';
$default-load-color: #ccc;

.theme-wrapper {
  --mainColor: $default-load-color;
  --secondaryColor: $default-load-color;
  --mainColorLight: $default-load-color;
  --scrollColor: $default-load-color;
  --markerNotCommunicated: $default-load-color;
  --markerActiveIgnitionOn: $default-load-color;
  --markerActiveIgnitionOff: $default-load-color;
  --markerPartial: $default-load-color;
  --markerNoDevice: $default-load-color;
}

$variables: (
  --mainColor: var(--mainColor),
  --secondaryColor: var(--secondaryColor),
  --mainColorLight: var(--mainColorLight),
  --scrollColor: var(--scrollColor),
  --markerNotCommunicated: var(--markerNotCommunicated),
  --markerActiveIgnitionOn: var(--markerActiveIgnitionOn),
  --markerActiveIgnitionOff: var(--markerActiveIgnitionOff),
  --markerPartial: var(--markerPartial),
  --markerNoDevice: var(--markerNoDevice),
);
