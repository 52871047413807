@import './styles/variables';
@import './styles/fonts';
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import './styles/main';
@import './theme';

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.psa {
  margin: 0;
}

::ng-deep .p-dialog .p-dialog-content {
  padding: 0;
}

.pac-container {
  z-index: 99999 !important;
}

::ng-deep .ag-paging-panel > * {
  font-weight: unset;
  color: unset;
}

.tenant-select-dialog {
  .p-dialog-content {
    background-color: #fafbfc;
  }
}

.htmlMarker {
  background-color: black;
  border-radius: 50%;
  padding: 10px;
  color: white;
}

.asset-on {
  fill: #9ae5eb;
  #ICON {
    fill: black !important;
  }
}

.asset-off {
  fill: #005cab;
}

.not-communicating {
  fill: #b72e2a;
}

.is-null {
  color: #b72e2a;
  font-size: 18px;
}

.detail-menu-container {
  z-index: 1;
  width: 200px;
  border-right: 1px solid $control-border-color;
}

*|*:fullscreen:not(:root) {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  min-width: 0 !important;
  max-width: none !important;
  min-height: 0 !important;
  max-height: none !important;
  box-sizing: border-box !important;
  object-fit: contain;
  transform: none !important;
}

.no-data-main {
  display: block;
  position: absolute;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.85);
  left: calc(50% - 150px);
  top: calc(45% - 150px);
  padding: 40px;
  border-radius: 8px;

  &.centered-position {
    display: unset;
    position: unset;
    padding: unset;
    left: unset;
    top: unset;
    margin-top: -20px;
  }

  &.v-1 {
    left: calc(50% - 195px);
    top: calc(50% - 145px);
  }
  &.v-2 {
    left: calc(50% - 130px);
    top: calc(50% - 105px);
  }
  &.v-3 {
    left: calc(50% - 122px);
    top: calc(50% - 106px);
  }

  &.v-4 {
    left: calc(50% - 122px);
    top: calc(50% - 120px);
  }
}

.no-data-side-menu {
  display: block;
  margin: auto;
  text-align: center;
}

.no-data-small {
  font-size: 14px !important;
  color: #ababab !important;
}

//global grid and tabs

.asset-transform {
  transform: translate(10px, 10px);
}

.asset-status-id {
  height: calc(3.2rem);
}

.asset-status-with-id {
  height: calc(3.2rem);
}

.list-grid-container {
  height: 100%;
  width: 100%;
  flex: 1;
  margin-top: 25px;
}

.asset-list-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
  height: 100%;
}

app-list-view-toggle {
  position: absolute;
  right: 20px;
  top: 10px;
  z-index: 1;
  &.map-view {
    color: red;
    margin-right: 40px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  }
}

.search-container-grid {
  .search {
    height: 2.5rem;
  }
  i {
    margin-top: 3px;
  }
}

.search-container {
  .search {
    height: 2.5rem;
  }
  i {
    margin-top: 3px;
  }
}

.icon-dropdown {
  border: none;
  span {
    padding-right: 0;
  }
  .p-dropdown-label,
  .p-dropdown-trigger {
    display: none;
  }
}

gridster-preview {
  display: none !important;
}

ag-grid-angular {
  min-height: 400px;
}
