// Theme Specific Variables
$primaryColor: #2196f3;
$primaryDarkColor: #1e88e5;
$primaryDarkerColor: #1976d2;
$primaryTextColor: #ffffff;

$bgwhite: #ffff;
$bgHover: #ffff;
$textHoverActiveColor: #005cab;

//Timeline Variables
$timelineEventMarkerBorder: 2px solid #2196f3;
$timelineEventMarkerBorderRadius: 50%;
$timelineEventMarkerWidth: 1rem;
$timelineEventMarkerHeight: 1rem;
$timelineEventMarkerBackground: #ffffff;
$timelineEventColor: #dee2e6;
$timelineVerticalEventContentPadding: 0 1rem;
$timelineHorizontalEventContentPadding: 0 1rem;
$timelineEventConnectorSize: 2px;

// Mandatory Designer Variables
@import './variables/general';
@import './variables/form';
@import './variables/button';
@import './variables/_panel';
@import './variables/_data';
@import './variables/_overlay';
@import './variables/_message';
@import './variables/_menu';
@import './variables/_media';
@import './variables/_misc';

:root {
  --surface-a: #ffffff;
  --surface-b: #f8f9fa;
  --surface-c: #e9ecef;
  --surface-d: #dee2e6;
  --surface-e: #ffffff;
  --surface-f: #ffffff;
  --text-color: #495057;
  --text-color-secondary: #6c757d;
  --primary-color: #2196f3;
  --primary-color-text: #ffffff;
  --font-family: 'Rubik';
}
