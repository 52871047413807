@import '../styles/variables';

body {
  font-family: $primary-font;
  font-size: $primary-fs;
  font-weight: $regular;
}

html,
body {
  height: 100%;
  overflow-x: hidden;
  font-size: 16px;
}

.flex-container {
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

@import '@ag-grid-community/styles/ag-grid.min.css';
@import '@ag-grid-community/styles/ag-theme-material.min.css';

.ag-theme-material {
  --ag-checkbox-checked-color: var(--mainColor);
}

.ag-header-viewport {
  background: #fff;
}

@import 'ng-select.theme.scss';

//partials are required for styling deep elements of third party components
//angular component styles only apply to component template

@import '../app/shared/components/asset-common/partials';
@import '../app/perspio/inspector/partials';
@import '../app/perspio/search/partials';

@import './prime-theme/theme.scss';

.centered-container {
  display: flex; /* establish flex container */
  flex-direction: column; /* make main axis vertical */
  justify-content: center; /* center items vertically, in this case */
  align-items: center; /* center items horizontally, in this case */
  height: 100%;

  div {
    width: 300px;
    margin: 5px;
    text-align: center; /* will center text in <p>, which is not a flex item */
  }
}

.p-dropdown-panel {
  color: red !important;
}

.custom-marker-config,
.default-marker-wrap {
  color: blue !important;

  svg {
    transform: none !important;
    width: 35px;
    height: 35px;
  }
  .p-dropdown-item {
    padding: 0rem 0.5rem !important;
  }
}
.custom-marker-wrap {
  .comp-marker {
    transform: none !important;
    width: 35px;
    height: 35px;
  }
}

.heading {
  font-size: 1.625rem;
  font-weight: 600;
  color: $control-text-color-dark;
}

.header {
  i {
    color: var(--mainColor);
  }
}

.p-toggleable-content {
  &:hover {
    color: var(--mainColor);
  }
}

.pac-container {
  font-family: $primary-font;
  &:after {
    background-image: none;
  }

  .pac-item {
    padding: 6px;
  }
}

// Google Maps button styles
.gm-style button,
.crosshair-container,
.nearby-container,
.crosshair-container,
.map-control-toggle,
.boundall-container,
.places-container,
google-map .gm-bundled-control div {
  border-radius: $border-radius !important;
}

.error {
  color: #c41f3d;
  font-size: 12px;
  margin-top: 3px;
}
