.asset-option-toggle {
  height: 80px;
  width: 100%;
  display: flex;

  .pi {
    font-size: 19px;
  }

  .p-button {
    height: 80px;
    border-radius: 0px;
    border-right: none;
    border-left: none;
    width: 33%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:last-child {
      width: 34%;
    }
  }

  .custom-i {
    font-size: 17px;
  }

  .p-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
}
div.btn-wrap.flex {
  display: flex;
}
